.main {
  background-image: url(../assets/svg/background_water5_5_Converted_copy.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.fixed-whatsapp {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 9999;
}

.fixed-whatsapp img {
  width: 50px;
  height: 50px;
}

.fixed-phone-call {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
}

.fixed-phone-call img {
  width: 50px;
  height: 50px;
}
.first-gap {
  height: 15vh;
}

@media only screen and (max-width: 991px) {
  .first-gap {
    height: 3vh;
  }
}
