.carousel-container {
  overflow: hidden;
  min-height: 55vh;
}

.carousel-item.active {
  border: none !important;
}

.img-side-container {
  width: 50%;
  height: 100%;
}

.img-side-container > div {
  padding-left: 150px;
}

.text-container > div {
  padding-right: 150px;
}

@media only screen and (max-width: 991px) {
  .carousel-indicators {
    display: none !important;
  }
  .text-container,
  .img-side-container {
    width: 100%;
    padding: 0;
  }
  .text-container > div {
    padding-right: unset;
  }
  .img-side-container > div {
    padding-left: unset;
  }
}

@media only screen and (max-width: 480px) {
  .carousel-item p {
    width: 100% !important;
    padding: 0 !important;
  }
}

.carousel-item p,
h4 {
  color: black !important;
}

.carousel-item p {
  margin: 15px auto;
  width: 80% !important;
  text-align: center;
  padding: 0 20px;
  font-size: 20px;
  font-weight: 600;
}

.carousel-item h4 {
  font-weight: bold;
}

.carousel-item h3 {
  font-weight: bold;
}

.img-container {
  width: 600px;
  height: 550px;
}

.img-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

span.carousel-control-prev-icon,
span.carousel-control-next-icon {
  background-color: black !important;
  border-radius: 50%;
  background-size: 70%;
  padding: 20px;
  background-position: center;
}
